import React, { useMemo } from 'react'
import DashboardWidgetWrapper from './DashboardWidgetWrapper'
import ReactECharts from "echarts-for-react";

const ChangeInOiWidget = ({oiData, chartStyle}) => {

    const putValue = useMemo(() => {
        if(chartStyle === 'bar' && oiData.length > 0) {
            
            const currentTf = oiData[oiData.length - 1];
            const totalOiAtCurrentTf = currentTf[Object.keys(currentTf)[0]].reduce((total, current) => total + current.PE, 0);
            const startTf = oiData[0];
            const totalOiAtStartTf = startTf[Object.keys(startTf)[0]].reduce((total, current) => total + current.PE, 0);

            return totalOiAtCurrentTf - totalOiAtStartTf;
        }

        else if(chartStyle === 'line' && oiData.length > 0) {
            const currentTf = oiData[oiData.length - 1];
            const totalOiAtCurrentTf = currentTf[Object.keys(currentTf)[0]].PE;
            const startTf = oiData[0]
            const totalOiAtStartTf = startTf[Object.keys(startTf)[0]].PE;

            return totalOiAtCurrentTf - totalOiAtStartTf;
        }

    }, [oiData, chartStyle])    

    const callValue = useMemo(() => {
        if (chartStyle === 'bar' && oiData.length > 0) {
            const currentTf = oiData[oiData.length - 1];
            const totalOiAtCurrentTf = currentTf[Object.keys(currentTf)[0]].reduce((total, current) => total + current.CE, 0);
            const startTf = oiData[0]
            const totalOiAtStartTf = startTf[Object.keys(startTf)[0]].reduce((total, current) => total + current.CE, 0);

            return totalOiAtCurrentTf - totalOiAtStartTf
        }

        else if (chartStyle === 'line' && oiData.length > 0) {
            const currentTf = oiData[oiData.length - 1];
            const totalOiAtCurrentTf = currentTf[Object.keys(currentTf)[0]].CE;
            const startTf = oiData[0]
            const totalOiAtStartTf = startTf[Object.keys(startTf)[0]].CE;

            return totalOiAtCurrentTf - totalOiAtStartTf;
        }
    }, [oiData, chartStyle])

    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '13%',
            top: 0,
            right: 0,
            bottom: 0,
        },
        xAxis: {
            type: 'value',
            axisLabel: { show: false }
        },
        textStyle: { fontFamily: 'Poppins' },
        yAxis: {
            type: 'category',
            data: ['Put', 'Call'],
            axisTick: { show: false },
        },
        series: [
            {
                data: [{ name: 'Put', value: putValue, itemStyle: { color: '#F23645' } }, { name: 'Call', value: callValue, itemStyle: { color: '#089880' }}],
                type: 'bar'
            },
        ],
    }
  return (
    <>
    <DashboardWidgetWrapper>
        <span className='text-base font-medium'>Change in OI</span>
        <ReactECharts option={options} style={{ height: '115px', width: '100%' }} />
    </DashboardWidgetWrapper>
    </>
  )
}

export default ChangeInOiWidget
