import ReactECharts from "echarts-for-react";
import React, { useMemo } from 'react';

const OiChart = ({oiData, chartStyle, chartType, strikeLimit, ltp}) => {

    const series = useMemo(() => {
        if(chartStyle === 'bar' && oiData.length > 0) {
            const currentTf = oiData[oiData.length - 1];
            let series = currentTf[Object.keys(currentTf)[0]].map(data => data.strike);
            const closestIndex = series.reduce((prevIndex, currValue, index) => {
                return Math.abs(parseInt(currValue) - ltp) < Math.abs(series[prevIndex] - ltp) ? index : prevIndex;
            }, 0);
            const start = Math.max(0, closestIndex - strikeLimit);
            const end = Math.min(series.length, closestIndex + strikeLimit);
            return series.slice(start, end);
        }

        else if(chartStyle === 'line' && oiData.length > 0) {
            return oiData.map(data => Object.keys(data)[0])
        }

    }, [oiData, chartStyle, ltp])
    
    const putData = useMemo(() => {
        if(chartStyle === 'bar' && oiData.length > 0) {
            if (chartType === "open interest") {
                const currentTf = oiData[oiData.length - 1];
                const peData = series.map(data => currentTf[Object.keys(currentTf)[0]].find(putData => putData.strike == data).PE);
                return peData;
            }

            else if (chartType === "change in oi") {
                const currentTf = oiData[oiData.length - 1];
                const startTf = oiData.find(data => Object.keys(data)[0] === "09:16") ?? oiData[0];
                const startTfData = startTf[Object.keys(startTf)[0]];
                const peData = series.map((data) => [data, Object.values(currentTf)[0].find(putData => putData.strike == data).PE - startTfData.find(putData => putData.strike == data).PE]);
                return peData;
            }

            else if (chartType === "pcr") {
                const currentTf = oiData[oiData.length - 1];
                const pcrData = series.map((data) => [data, (Object.values(currentTf)[0].find(putData => putData.strike == data).PE / Object.values(currentTf)[0].find(putData => putData.strike == data).CE).toFixed(3)]);
                return pcrData;
            }
        }
        else if (chartStyle === 'line' && oiData.length > 0) {
            if (chartType === "open interest") {
                return oiData.map((data, index) => [series[index], data[Object.keys(data)[0]].PE]);
            }

            else if (chartType === "change in oi") {
                const startTf = oiData.find(data => Object.keys(data)[0] === "09:16") ?? oiData[0];
                const startTfData = startTf[Object.keys(startTf)[0]];
                const peData = oiData.map((data, index) => [series[index], data[Object.keys(data)[0]].PE - startTfData.PE]);
                return peData;
            }

            else if (chartType === "pcr") {
                return oiData.map((data, index) => [series[index], (data[Object.keys(data)[0]].PE / data[Object.keys(data)[0]].CE).toFixed(3)])
            }
        }
    }, [oiData, chartStyle, chartType, series])
    
    const callData = useMemo(() => {
        if (chartStyle === 'bar' && oiData.length > 0) {
            if (chartType === "open interest") {
                const currentTf = oiData[oiData.length - 1];
                const ceData = series.map(data => currentTf[Object.keys(currentTf)[0]].find(callData => callData.strike == data).CE);
                return ceData;
            }

            else if (chartType === "change in oi") {
                const currentTf = oiData[oiData.length - 1];
                const startTf = oiData.find(data => Object.keys(data)[0] === "09:16") ?? oiData[0];
                const startTfData = startTf[Object.keys(startTf)[0]];
                const ceData = series.map((data) => [data, Object.values(currentTf)[0].find(callData => callData.strike == data).CE - startTfData.find(callData => callData.strike == data).CE])
                return ceData;
            }
        }
        else if (chartStyle === 'line' && oiData.length > 0) {
            if (chartType === "open interest") {
                return oiData.map((data, index) => [series[index], data[Object.keys(data)[0]].CE]);
            }

            else if (chartType === "change in oi") {
                const startTf = oiData.find(data => Object.keys(data)[0] === "09:16") ?? oiData[0];
                const startTfData = startTf[Object.keys(startTf)[0]];
                const ceData = oiData.map((data, index) => [series[index], data[Object.keys(data)[0]].CE - startTfData.CE]);
                return ceData;
            }
        }
    }, [oiData, chartStyle, chartType, series])

    const findClosest = (arr, n, target) => {
        let left = 0,
            right = n - 1;
        while (left < right) {
            if (Math.abs(arr[left] - target) <= Math.abs(arr[right] - target)) {
                right--;
            } else {
                left++;
            }
        }
        return arr[left];
    };

    const options = useMemo(() => (series ? {
        grid: {
            left: '1%',
            top: '5%',
            right: '2%',
            bottom: '5%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: series,
            axisTick: { show: false },
            axisLabel: {
                formatter: (value) => value === ltp?.toString() ? "" : value
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: (value) => {
                    if (parseFloat(Math.abs(value)) < 1000) {
                        return parseFloat(value).toString();
                    } else if (parseFloat(Math.abs(value)) < 100000) {
                        return (parseFloat(value) / 1000).toFixed(1) + 'K';
                    } else if (parseFloat(Math.abs(value)) < 10000000) {
                        return (parseFloat(value) / 100000).toFixed(1) + 'L';
                    } else {
                        return (parseFloat(value) / 10000000).toFixed(1) + 'Cr';
                    }
                }
            }
        },
        textStyle: {fontFamily: 'Poppins'},
        series: [
            chartType === 'pcr' ? 
            {
                    name: 'PCR',
                    type: chartStyle,
                    ...(chartStyle === 'line' && { symbol: 'none' }),
                    barGap: 0.2,
                    emphasis: {
                        focus: 'series'
                    },
                    data: putData
            }
            :
            {
                name: 'Put',
                type: chartStyle,
                ...(chartStyle === 'line' && {symbol: 'none'}),
                barGap: 0.2,
                emphasis: {
                    focus: 'series'
                },
                data: putData,
            },
            {
                name: 'Call',
                type: chartStyle,
                ...(chartStyle === 'line' && { symbol: 'none' }),
                emphasis: {
                    focus: 'series'
                },
                data: callData,
                ...(ltp && chartStyle === "bar" && {markLine: {
                    label: {
                        show: false
                    },
                    lineStyle: {
                        color: "#3E4784",
                        width: 2
                    },
                    symbol: "none",
                    data: [{
                        xAxis: findClosest(series, series.length, ltp?.toString())
                    }],
                }}),
            },
        ],
        color: ['#F23645', '#089880' ]
    } : {}), [series])

    return (
        <ReactECharts option={options} style={{ width: '100%' }} />
    )
}

export default OiChart