import React from 'react'
import DashboardWidgetWrapper from './DashboardWidgetWrapper'
import ReactECharts from "echarts-for-react";

const TotalOiWidget = ({ oiData, chartStyle }) => {
    
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '13%',
            top: 0,
            right: 0,
            bottom: 0,
        },
        xAxis: {
            type: 'value',
            axisLabel: { show: false }
        },
        textStyle: { fontFamily: 'Poppins' },
        yAxis: {
            type: 'category',
            data: ['Put', 'Call'],
            axisTick: { show: false },
        },
        series: [
            {
                data: [
                    { name: 'Put', value: chartStyle === 'bar' ? oiData.length > 0 && oiData[oiData.length - 1].total_pe : oiData[oiData.length - 1][Object.keys(oiData[oiData.length - 1])[0]].PE, itemStyle: { color: '#F23645' } }, 

                    { name: 'Call', value: chartStyle === 'bar' ? oiData.length > 0 && oiData[oiData.length - 1].total_ce : oiData[oiData.length - 1][Object.keys(oiData[oiData.length - 1])[0]].CE, itemStyle: { color: '#089880' } }
                ],
                type: 'bar'
            },
        ],
    }
    return (
        <>
            <DashboardWidgetWrapper>
                <span className='text-base font-medium'>Total OI</span>
                <ReactECharts option={options} style={{ height: '115px', width: '100%' }} />
            </DashboardWidgetWrapper>
        </>
    )
}

export default TotalOiWidget