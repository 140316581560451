import {
    Autocomplete,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    createFilterOptions
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createAxiosInstance } from "../../Utils/axiosConfig";
import LoadingBackdrop from "../common/LoadingBackdrop";
import TextInput from "../common/TextInput";

const filterOptions = createFilterOptions({
    matchFrom: "start",
});

export const DynamicExpiries = {
    CW: "Current Week",
    NW: "Next week",
    CM: "Current Month",
    NM: "Next month"
}

function BasicStrategyDetails({
    strategyDetails,
    setStrategyDetails,
    optionInstrumentsList,
    mode,
    setExpiryDates
}) {

    const [loading, setLoading] = useState(false);
    const [lotSize, setLotSize] = useState(0);

    useEffect(() => {
        async function getExpiryDates() {
            if (strategyDetails.instrument != null) {
                if(!strategyDetails.is_dynamic) {
                    setLoading(true);

                    const axiosInstance = createAxiosInstance(true);
                    const response = await axiosInstance.get(`trades/master-expiry/?instru_name=${strategyDetails.instrument}`);
                    if (response.status === 200) {
                        let expiries = response.data[strategyDetails.instrument].expiry;
                        expiries = expiries.map(expiry => {
                            return moment(expiry).format('DD MMM YYYY')
                        })

                        expiries = expiries.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD'))
                        setExpiryDates(expiries)
                        setLotSize(response.data[strategyDetails.instrument].lotsize);
                    }

                    setLoading(false);
                }
                else {
                    setExpiryDates(Object.keys(DynamicExpiries));
                }
            }
        }

        getExpiryDates();

    }, [strategyDetails.instrument, strategyDetails.is_dynamic])

    const handleInstrumentChange = async (event, value) => {
        setStrategyDetails((prevData) => {
            return {
                ...prevData,
                instrument: value === null ? null : value.instrument,
            };
        });
    };

    const handleChange = async (event) => {
        const { name, value } = event.target;
        setStrategyDetails((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    return (
        <>
            <Grid container rowSpacing={0} columnSpacing={4}>
                <Grid item xs={3}>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            Strategy name<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            value={strategyDetails.name}
                            name="name"
                            size="small"
                            fullWidth
                            placeholder="Enter strategy name"
                            autoComplete="off"
                            onChange={handleChange}
                        />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            Instrument<span className="text-red-700">*</span>
                        </label>
                        <Autocomplete
                            value={strategyDetails.instrument}
                            openOnFocus={true}
                            size="small"
                            disabled={mode === 'edit'}
                            options={optionInstrumentsList.sort((a, b) => -b.instrument.localeCompare(a.instrument)).sort((a, b) => -b.type.localeCompare(a.type))}
                            groupBy={(option) => option.type}
                            getOptionLabel={(option) => option.instrument ? option.instrument : strategyDetails.instrument}
                            getOptionDisabled={(option) => strategyDetails.strategySelected === "OI" && (option.type === "Stock" || ["FINNIFTY", "MIDCPNIFTY"].includes(option.instrument))}
                            onChange={handleInstrumentChange}
                            clearOnEscape
                            clearOnBlur
                            noOptionsText="No instruments"
                            isOptionEqualToValue={(option, value) => option.instrument === value}
                            filterOptions={filterOptions}
                            autoComplete={true}
                            renderInput={(params) => (
                                <TextInput {...params} placeholder="Select an instrument" />
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            No. of lots<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            name="lots"
                            value={strategyDetails.lots}
                            type="number"
                            size="small"
                            onChange={handleChange}
                            placeholder="Select number of lots"
                            autoComplete="off"
                            helperText={`Lot size: ${lotSize}`}
                            sx={{"& .MuiFormHelperText-root": {marginLeft: "4px", marginTop: 0  }}}
                        />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            Execution<span className="text-red-700">*</span>
                        </label>
                        <RadioGroup
                            row
                            name="execution"
                            defaultValue={mode === 'edit' && strategyDetails.trades[0].order_type === "B" ? "buy" : "sell"}
                            value={strategyDetails.execution}

                            onChange={handleChange}
                        >
                            <div className="grid grid-cols-2 gap-1 w-full">
                                <div className="px-4 rounded-lg border border-[#6CE9A6] bg-[#ECFDF3]">
                                    <FormControlLabel
                                        sx={{ fontSize: '14px' }}
                                        value="buy"
                                        disableTypography
                                        disabled={mode === 'edit' || strategyDetails.strategySelected === "OI"}
                                        control={
                                            <Radio
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Buy"
                                    />
                                </div>
                                <div className="px-4 rounded-lg border border-[#EAECF0] bg-[#FEF3F2]">
                                    <FormControlLabel
                                        sx={{ fontSize: '14px' }}
                                        value="sell"
                                        disableTypography
                                        disabled={mode === 'edit' || strategyDetails.strategySelected === "OI"}
                                        control={
                                            <Radio
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Sell"
                                    />
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            Delivery<span className="text-red-700">*</span>
                        </label>
                        {strategyDetails.delivery && 
                        <RadioGroup
                            row
                            name="delivery"
                            value={strategyDetails.delivery}
                            onChange={handleChange}
                        >
                            <div className="flex gap-1 w-fit">
                                <div className="pl-4 rounded-lg border border-primary w-max">
                                    <FormControlLabel
                                        value="mis"
                                        sx={{ fontSize: '14px' }}
                                        disableTypography
                                        disabled={strategyDetails.strategySelected === "OI"}
                                        control={
                                            <Radio
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Intraday (MIS)"
                                    />
                                </div>
                                <div className="pl-4 rounded-lg border border-primary w-max">
                                    <FormControlLabel
                                        value="nrml"
                                        disableTypography
                                        sx={{ fontSize: '14px' }}
                                        disabled={strategyDetails.strategySelected === "OI"}
                                        control={
                                            <Radio
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Overnight (NRML)"
                                    />
                                </div>
                            </div>
                        </RadioGroup>}
                    </div>
                </Grid>
            </Grid>

            {loading && <LoadingBackdrop loading={loading} />}

        </>
    );
}

export default BasicStrategyDetails;
