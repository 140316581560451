import { BarChart, ShowChart } from "@mui/icons-material";
import { Autocomplete, Box, Button, createFilterOptions, Tab, Tabs } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createAxiosInstance } from '../../Utils/axiosConfig';
import LoadingBackdrop from '../common/LoadingBackdrop';
import TextInput from '../common/TextInput';
import ChangeInOiWidget from './ChangeInOiWidget';
import OiChart from './OiChart';
import TotalOiWidget from './TotalOiWidget';

const filterOptions = createFilterOptions({
    matchFrom: "start",
});

const OiWidget = () => {
    const [loading, setLoading] = useState(false);
    const [oiInstruments, setOiInstrument] = useState([]);
    const [oiConfig, setOiConfig] = useState({
        instrument: null,
        strikeLimit: 15,
        expiry: null,
        chartType: "open interest",
        chartStyle: "bar"
    });
    const [oiData, setOiData] = useState([]);
    const [ltp, setLtp] = useState(null);

    const handleInstrumentChange = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            expiry: value.expiry[0],
            instrument: value
        }))
    }

    const handleChartTypeChange = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            chartType: value
        }))
    }

    const handleExpiryChange = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            expiry: value
        }))
    }

    const handleStrikeLimitChanage = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            strikeLimit: value
        }))
    }

    const handleChartStyleChange = (event, value) => {
        setOiData(undefined);
        setOiConfig(prev => ({
            ...prev,
            chartStyle: value
        }))
    }

    useEffect(() => {
        async function getOiInstruments() {
            setLoading(true);
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.get('trades/oi-instruments/');
            setLoading(false);
            setOiInstrument(response.data);
            setOiConfig(prev => ({
                ...prev,
                instrument: response.data.find(instrument => instrument.name === "NIFTY") ?? response.data[0],
                expiry: (response.data.find(instrument => instrument.name === "NIFTY") ?? response.data[0]).expiry.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD'))[0]
            }));
        }

        getOiInstruments()
    }, [])

    useEffect(() => {
        let oiInterval, ltpInterval;
        async function getOiData() {
            const axiosInstance = createAxiosInstance(true);
            const oiDataResponse = await axiosInstance.get(`trades/oi-data/?instrument=${oiConfig.instrument.name}&expiry=${oiConfig.expiry}&type=${oiConfig.chartStyle}`);
            if (oiDataResponse.data?.length > 0) {
                oiConfig.chartStyle === 'bar' ? setOiData(oiDataResponse.data) : setOiData(oiDataResponse.data);
            }
        }

        async function getLtp() {
            const axiosInstance = createAxiosInstance(true);
            const ltpResponse = await axiosInstance.get(`trades/get-instru/?instrument=${oiConfig.instrument.name}`);
            if (ltpResponse.status === 200) {
                const ltp = ltpResponse.data.ltp;
                setLtp(ltp);
            }
        }

        if (oiConfig.instrument !== null && oiConfig.expiry !== null) {
            getOiData();
            getLtp();
            oiInterval = setInterval(getOiData, 180000)
            ltpInterval = setInterval(getLtp, 180000)
        }

        return () => {
            clearInterval(oiInterval);
            clearInterval(ltpInterval);
        }
    }, [oiConfig])

    return (
        <>
            <div className='w-3/4 border border-[#c1c3c7] flex flex-col gap-6 rounded-b-md'>
                <Box sx={{ borderBottom: 1, borderColor: "#d9dbde" }}>
                    <Tabs value={oiConfig.chartType} onChange={handleChartTypeChange}>
                        <Tab sx={{ textTransform: "none", "&.Mui-selected": { backgroundColor: "#d8dbf2" } }} value="open interest" label="Open Interest" />
                        <Tab sx={{ textTransform: "none", "&.Mui-selected": { backgroundColor: "#d8dbf2" } }} value="change in oi" label="Change in Open Interest" />
                        <Tab sx={{ textTransform: "none", "&.Mui-selected": { backgroundColor: "#d8dbf2" } }} value="pcr" label="Put Call Ratio" />
                    </Tabs>
                </Box>

                <div className="flex justify-between items-end px-4">
                    <div className='flex items-center gap-6 w-3/5'>
                        <div className="flex flex-col gap-3 w-2/5">
                            <span className='text-sm font-medium'>Instrument</span>
                            <Autocomplete
                                value={oiConfig.instrument}
                                openOnFocus={true}
                                size="small"
                                options={oiInstruments}
                                getOptionLabel={(option) => option.name}
                                clearOnEscape
                                clearOnBlur
                                noOptionsText="No instruments"
                                onChange={handleInstrumentChange}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                filterOptions={filterOptions}
                                autoComplete={true}
                                renderInput={(params) => (
                                    <TextInput {...params} placeholder="Select an instrument" />
                                )}
                            />
                        </div>

                        <div className='flex flex-col gap-3 w-2/3'>
                            <span className='text-sm font-medium'>Expiry Date</span>
                            <Autocomplete
                                value={oiConfig.expiry}
                                openOnFocus={true}
                                size="small"
                                options={oiConfig.instrument?.expiry?.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')) ?? []}
                                clearOnEscape
                                clearOnBlur
                                noOptionsText="No expiries"
                                onChange={handleExpiryChange}
                                filterOptions={filterOptions}
                                autoComplete={true}
                                renderInput={(params) => (
                                    <TextInput {...params} placeholder="Select an expiry" />
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-3 w-1/3">
                            <span className='text-sm font-medium'>Strike Limit</span>
                            <Autocomplete
                                value={oiConfig.strikeLimit}
                                openOnFocus={true}
                                size="small"
                                options={[5, 10, 15]}
                                getOptionLabel={option => option.toString()}
                                onChange={handleStrikeLimitChanage}
                                disableClearable
                                clearOnEscape
                                clearOnBlur
                                autoComplete={true}
                                renderInput={(params) => (
                                    <TextInput {...params} placeholder="Select strike limit" />
                                )}
                            />
                        </div>
                    </div>

                    {oiConfig.chartStyle === "bar" ? 
                    <Button variant="text" startIcon={<ShowChart />} sx={{ textTransform: "none" }} onClick={(e) => handleChartStyleChange(e, "line")}>
                        Line chart
                    </Button> : 
                    <Button variant="text" startIcon={<BarChart />} sx={{ textTransform: "none" }} onClick={(e) => handleChartStyleChange(e, "bar")}>
                        Bar chart
                    </Button>
                    }
                </div>


                {oiData && <OiChart oiData={oiData} chartStyle={oiConfig.chartStyle} chartType={oiConfig.chartType} strikeLimit={oiConfig.strikeLimit} ltp={ltp} />}
            </div>
            
            <div className="flex flex-col gap-3 w-1/4">
                {oiData && <ChangeInOiWidget oiData={oiData} chartStyle={oiConfig.chartStyle} />}

                {oiData && <TotalOiWidget oiData={oiData} chartStyle={oiConfig.chartStyle} />}
            </div>
            {loading && <LoadingBackdrop loading={loading} />}
        </>
    )
}

export default OiWidget